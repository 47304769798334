@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import './mixins.scss';
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  font-family: 'Raleway', sans-serif;
  scroll-behavior: smooth;
  &:focus,
  &:active{
    outline: none;
  }
}
html,
body{
  height: 100%;
  font-size: 16px;
}
a{
  text-decoration: none;
}