@import '../../styles/mixins.scss';
.info{
    @include res('padding-top', 120px);
    @include res('padding-bottom', 120px);
    @include media(576){
        @include resa('padding-top', 64px);
        @include resa('padding-bottom', 64px);
    }
}
.body{
    @include res('width', 1200px);
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-wrap: wrap;
    @include res('gap', 24px);
    @include media(576){
        @include resa('width', 330px);
        @include resa('gap', 16px);
        flex-direction: column;
    }
}
.title{
    color: #1F2723;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    @include res('font-size', 36px);
    @include media(576){
        @include resa('margin-bottom', 16px);
        @include resa('font-size', 28px);
        text-align: center;
    }
    span{
        color: #17AB49;
        font-weight: 700;
    }
}
.item{
    @include res('width', 384px);
    @include res('height', 200px);
    @include media(576){
        width: 100%;
        @include resa('height', 160px);
    }
}
.intro{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @include media(576){
        justify-content: center;
        height: max-content;
    }
}
.el{
    position: relative;
    background: #F9FDFC;
    @include res('border-radius', 24px);
    @include res('padding', 24px);
    @include media(576){
        @include resa('border-radius', 24px);
        @include resa('padding', 24px);
    }
    &Image{
        position: absolute;
        top: 0;
        right: 0;
        @include res('width', 200px);
        @include res('height', 200px);
        line-height: 0;
        @include media(576){
            @include resa('width', 160px);
            @include resa('height', 160px);
        }
        img{
            width: 100%;
            height: 100%;
        }
    }
    &Title{
        color: #1F2723;
        @include res('font-size', 16px);
        @include res('margin-bottom', 8px);
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        @include media(576){
            @include resa('font-size', 16px);
            @include resa('margin-bottom', 4px);
        }
    }
    &P{
        color: #8A978D;
        @include res('font-size', 14px);
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        @include media(576){
            @include resa('font-size', 14px);
            @include resa('max-width', 145px);
        }
    }
}