@import '../../styles/mixins.scss';
.intro{
    @include res('height', 800px);
    @include res('padding-top', 187px);
    @include res('padding-bottom', 100px);
    background: url('../../../public/intro-bg.png') center / cover no-repeat;
    @include media(576){
        @include resa('height', 827px);
        @include resa('padding-top', 97px);
        @include resa('padding-bottom', 40px);
    }
}
.body{
    @include res('width', 1200px);
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @include media(576){
        @include resa('width', 330px);
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}
.title{
    color: #1F2723;
    @include res('font-size', 60px);
    font-style: normal;
    font-weight: 800;
    line-height: 120%;
    text-transform: uppercase;
    @include res('max-width', 500px);
    @include res('margin-bottom', 36px);
    @include media(576){
        @include resa('font-size', 32px);
        @include resa('max-width', 240px);
        @include resa('margin-bottom', 20px);
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }
    span{
        color: #17AB49;
    }
}
.items{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @include res('gap', 40px);
    @include res('margin-bottom', 56px);
    @include media(576){
        align-items: flex-start;
        justify-content: center;
        @include resa('gap', 12px);
        @include resa('margin-bottom', 28px);
        margin-left: auto;
        margin-right: auto;
    }
}
.item{
    @include res('min-width', 120px);
    @include media(576){
        @include resa('min-width', 102px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        &:nth-child(1){
            order: -2;
        }
        &:nth-child(3){
            order: -1;
            .itemTitle{
                display: block;
                @include resa('margin-left', -9px);
                @include resa('margin-right', -9px);
                @include resa('min-width', 120px);
            }
        }
    }
    &Icon{
        @include res('width', 60px);
        @include res('height', 60px);
        @include res('border-radius', 16px);
        background: rgba(48, 236, 112, 0.12);
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 0;
        @include res('margin-bottom', 20px);
        @include media(576){
            @include resa('width', 50px);
            @include resa('height', 50px);
            @include resa('border-radius', 16px);
            @include resa('margin-bottom', 16px);
        }
        img{
            @include res('width', 28px);
            @include res('height', 28px);
            @include media(576){
                @include resa('width', 24px);
                @include resa('height', 24px);
            }
        }
    }
    &Title{
        color: #8A978D;
        @include res('font-size', 17px);
        font-style: normal;
        font-weight: 500;
        line-height: 160%;
        @include media(576){
            text-align: center;
            @include resa('font-size', 14px);
        }
        span{
            color: #1F2723;
            font-weight: 600;
        }
    }
}
.p{
    @include res('margin-bottom', 28px);
    color: #1F2723;
    font-size: 18px;
    @include res('font-size', 18px);
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    @include media(576){
        @include resa('margin-bottom', 20px);
        @include resa('font-size', 16px);
        text-align: center;
    }
}
.row{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @include res('gap', 20px);
    @include media(576){
        @include resa('gap', 12px);
    }
}
.app{
    @include res('padding-top', 10px);
    @include res('padding-bottom', 10px);
    @include res('padding-left', 20px);
    @include res('padding-right', 20px);
    @include res('border-radius', 8px);
    @include res('height', 60px);
    line-height: 0;
    background: #1F2723;
    transition: background 300ms ease-out;
    @include media(576){
        @include resa('padding-top', 10px);
        @include resa('padding-bottom', 10px);
        @include resa('padding-left', 12px);
        @include resa('padding-right', 12px);
        @include resa('border-radius', 8px);
        @include resa('height', 56px);
    }
    img{
        width: 100%;
        height: 100%;
    }
    &:hover{
        background: #199843;
    }
    &:active{
        background: #19843D;
    }
}
.poster{
    border: solid rgba(210, 248, 224, 0.60);
    line-height: 0;
    position: relative;
    cursor: pointer;
    @include res('width', 600px);
    @include res('height', 400px);
    @include res('border-width', 12px);
    @include res('border-radius', 32px);
    @include res('margin-bottom', 32px);
    @include media(576){
        @include resa('width', 330px);
        @include resa('height', 220px);
        @include resa('border-width', 8px);
        @include resa('border-radius', 24px);
        @include resa('margin-bottom', 28px);
    }
    &.sm{
        display: none;
        @include media(576){
            display: block;
        }
    }
    &.lg{
        @include media(576){
            display: none;
        }
    }
    img{
        width: 100%;
        height: 100%;
        @include res('border-radius', 22px);
        @include media(576){
            @include resa('border-radius', 16px);
        }
    }
    &Btn{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        border-radius: 50%;
        background: linear-gradient(135deg, #7BFFA8 0%, #00A638 100%);
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background 300ms ease-out;
        @include res('padding-left', 4px);
        @include res('width', 80px);
        @include res('height', 80px);
        @include media(576){
            @include resa('padding-left', 2px);
            @include resa('width', 60px);
            @include resa('height', 60px);
        }
        svg{
            @include res('width', 30px);
            @include res('height', 30px);
            @include media(576){
                @include resa('width', 24px);
                @include resa('height', 24px);
            }
        }
        &:hover{
            background: linear-gradient(0deg, rgba(31, 39, 35, 0.15) 0%, rgba(31, 39, 35, 0.15) 100%), linear-gradient(135deg, #7BFFA8 0%, #00A638 100%);
        }
        &:active{
            background: linear-gradient(0deg, rgba(31, 39, 35, 0.30) 0%, rgba(31, 39, 35, 0.30) 100%), linear-gradient(135deg, #7BFFA8 0%, #00A638 100%);
        }
    }
}
.info{
    border: solid #C0E9D0;
    color: #8A978D;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    position: relative;
    width: max-content;
    @include res('font-size', 16px);
    @include res('padding-top', 16px);
    @include res('padding-bottom', 16px);
    @include res('padding-left', 20px);
    @include res('padding-right', 20px);
    @include res('border-radius', 16px);
    @include res('border-width', 1px);
    @include media(576){
        border: none;
        @include resa('font-size', 15px);
        border-radius: 0;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        padding: 0;
        @include resa('margin-bottom', 12px);
    }
    &.sm{
        display: none;
        @include media(576){
            display: block;
        }
    }
    &.lg{
        @include media(576){
            display: none;
        }
    }
    span{
        color: #17AB49;
        font-weight: 600;
    }
    svg{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        @include res('right', -82px);
        @include res('width', 62px);
        @include res('height', 48px);
        @include media(576){
            display: none;
        }
    }
}
.video{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 500;
    isolation: isolate;
    display: flex;
    align-items: center;
    justify-content: center;
    &Overlay{
        background: #00000080;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        cursor: pointer;
    }
    &Inner{
        @include res('width', 1440px);
        height: 0;
        padding-bottom: 56.25%;
        position: relative;
        @include media(576){
            @include resa('width', 330px);
        }
    }
    &Wrap{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: solid rgba(210, 248, 224, 0.60);
        @include res('border-width', 12px);
        @include res('border-radius', 32px);
        overflow: hidden;
        @include media(576){
            border: solid rgba(210, 248, 224, 1);
            @include resa('border-width', 4px);
            @include resa('border-radius', 24px);
        }
        video{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}