@import '../../styles/mixins.scss';
.header{
    position: absolute;
    top: 0;
    left: 0;
    @include res('height', 85px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: solid rgba(23, 171, 73, 0.10);
    @include res('border-width', 1px);
    z-index: 5;
    @include media(576){
        @include resa('height', 57px);
        @include resa('border-width', 1px);
    }
}
.body{
    @include res('width', 1200px);
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include media(576){
        @include resa('width', 330px);
    }
}
.logo{
    color: #1F2723;
    @include res('font-size', 20px);
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    @include res('letter-spacing', 0.6px);
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @include res('gap', 12px);
    @include media(576){
        @include resa('max-width', 110px);
        @include resa('font-size', 14px);
        @include resa('letter-spacing', 0.42px);
        @include resa('gap', 8px);
    }
    img{
        @include res('width', 32px);
        @include res('height', 32px);
        @include media(576){
            @include resa('width', 32px);
            @include resa('height', 32px);
        }
    }
    span{
        color: #17AB49;
    }
}
.info{
    @include res('border-radius', 12px);
    border: dashed #C0E9D0;
    @include res('border-width', 1px);
    @include res('padding-top', 8px);
    @include res('padding-bottom', 8px);
    @include res('padding-left', 16px);
    @include res('padding-right', 16px);
    display: flex;
    flex-direction: column;
    align-items: center;
    @include media(576){
        border: none;
        border-radius: 0;
        padding: 0;
    }
    &Title{
        color: #1F2723;
        @include res('font-size', 16px);
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        @include res('margin-bottom', 4px);
        @include media(576){
            @include resa('font-size', 13px);
            @include resa('margin-bottom', 4px);
        }
    }
    &Value{
        color: #17AB49;
        @include res('font-size', 18px);
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        @include media(576){
            @include resa('font-size', 14px);
        }
    }
}